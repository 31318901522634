// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

.navbar-light .nav-navbar > .nav-item > .nav-link, .navbar-light .nav-navbar > .nav-link, .navbar-stick-light.stick .nav-navbar > .nav-item > .nav-link, .navbar-stick-light.stick .nav-navbar > .nav-link {
  color: #fbfdff;
  text-shadow: 1px 1px #202020;
}

.navbar-brand img {
  max-height: 69px;
}

.nav-navbar .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}